<template>
  <div>
    <div class="main " style="background:#fff;min-height:900px">
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <h3>Product Management</h3>
            </div>
            <div class="col-lg-6">
              <div class="d-flex align-items-center">
                <v-btn
                  class="ma-2"
                  color="primary"
                  dark
                  @click="$router.push('/pim/product/new')"
                >
                  Add Product
                  <v-icon dark right>
                    mdi-checkbox-marked-circle
                  </v-icon>
                </v-btn>
                <v-btn
                  class="ma-2"
                  color="primary"
                  dark
                  @click="importProductsDialog = true"
                >
                  Import Products
                  <v-icon dark right>
                    mdi-checkbox-marked-circle
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="row">
            <v-toolbar flat>
              <v-toolbar-title>Products</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="tableSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                @keyup="onSearch"
              />
              <v-spacer></v-spacer>
            </v-toolbar>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <v-container fluid>
                <v-tabs v-model="activeTab" horizontal class="mt-8">
                  <v-tab
                    v-for="tab of tabs"
                    :key="tab.index"
                    @click="selected(tab.index)"
                  >
                    {{ tab.name }}
                  </v-tab>

                  <v-tab-item>
                    <v-row dense class="mt-4">
                      <v-col
                        v-for="product in listproducts"
                        :key="product.id"
                        :cols="3"
                      >
                        <v-hover v-slot="{ hover }">
                          <v-card
                            class="mx-auto  "
                            color="grey lighten-4"
                            max-width="600"
                            max-height="300"
                          >
                            <v-img
                              :src="
                                product.main_image != null
                                  ? product.main_image.url
                                  : '/media/product-placeholder.jpg'
                              "
                              :aspect-ratio="4 / 3"
                            >
                              <v-expand-transition>
                                <div
                                  v-if="hover"
                                  class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal  white--text"
                                  style="height: 100%;"
                                >
                                  <div class="container">
                                    <p>{{ product.name }}</p>
                                    <p
                                      v-if="product.short_description != null"
                                      style="font-size:12pt;"
                                      v-html="product.short_description"
                                    ></p>
                                  </div>
                                </div>
                              </v-expand-transition>
                            </v-img>
                            <v-card-text
                              class="pt-6"
                              style="position: relative;"
                            >
                              <v-btn
                                absolute
                                color="primary"
                                class="white--text"
                                fab
                                right
                                top
                                @click="
                                  $router.push(
                                    '/pim/product-detail/' + product.id
                                  )
                                "
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>

                              <h3
                                class=" limit display-1 font-weight-light orange--text mb-2"
                              >
                                {{ product.name }}
                              </h3>

                              <div class="font-weight-light grey--text  mb-2">
                                Part #: {{ product.part_id }}
                              </div>
                              <div class="font-weight-light title mb-2"></div>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>

                    <div class="row mt-8 mb-6">
                      <v-pagination
                        v-model="listcurrentPage"
                        :length="productsCountLength"
                        :total-visible="12"
                        style="margin: 0 auto"
                        @input="next"
                      ></v-pagination>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <!-- <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="6">
                        <v-text-field
                          v-model="tableSearch"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row> -->
                    <v-data-table
                      :items="listproducts"
                      hide-default-footer
                      :headers="headers"
                    >
                      <template #item="row">
                        <tr>
                          <td>{{ row.item.id }}</td>
                          <td>{{ row.item.name }}</td>
                          <td>{{ row.item.part_id }}</td>
                          <td>{{ row.item.status }}</td>
                          <td>
                            <v-btn
                              class="ma-2"
                              tile
                              outlined
                              color="alert"
                              @click="removeProduct(row.item.id)"
                            >
                              Remove
                            </v-btn>

                            <v-btn
                              class="ma-2"
                              tile
                              outlined
                              color="success"
                              @click="
                                $router.push(
                                  '/pim/product-detail/' + row.item.id
                                )
                              "
                            >
                              Edit
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>

                    <div class="row  mt-8 pb-8 ">
                      <v-pagination
                        v-model="listcurrentPage"
                        :length="productsCountLength"
                        :total-visible="12"
                        style="margin: 0 auto"
                        @input="next"
                      ></v-pagination>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </v-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="importProductsDialog">
      <ImportProducts
        @dialog-close="importProductsDialog = false"
        @refetch="refetch"
      />
    </v-dialog>
  </div>
</template>

<script>
// import { mapActions } from 'vuex'
import ImportProducts from '@/components/product/ImportProducts.vue'
// import CardProducts from '@/graphql/queries/CardProducts.gql'
import ListProducts from '@/graphql/queries/ListProducts.gql'
// import GetProductsCount from '@/graphql/queries/GetProductsCount.gql'
import GetProductListCount from '@/graphql/queries/GetProductListCount.gql'

export default {
  components: {
    ImportProducts,
  },
  data: () => ({
    productsCountLength: 1,
    productsCardCountLength: 1,
    importProductsDialog: false,
    listtotalCount: 0,
    tableSearch: '',
    name_search: '',
    part_id_search: '',
    activeTab: 0,
    tabs: [
      { index: 0, name: 'Card View' },
      { index: 1, name: 'List View' },
    ],
    id_search: '',
    search_option: 'name',
    search_options: [
      {
        value: 'name',
        text: 'Name',
      },
      {
        value: 'part_id',
        text: 'Part #',
      },
      {
        value: 'id',
        text: 'Id',
      },
    ],
    search: '',
    status: '',
    searchProduct: [],
    main_image_url: '',
    main_image: null,
    limit: 12,
    currentPage: 1,
    totalCount: 0,
    listlimit: 12,
    listcurrentPage: 1,
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match",
    },
    addDialog: false,
    addDialogformvalid: false,
    addDialogform: {
      name: '',
      partid: '',
      featured: false,
    },
    tags: [],
    addchips: [],
    // cardproducts: [],
    listproducts: [],

    baseurl: '',
    headers: [
      { text: 'ID' },
      { text: 'Name' },
      { text: 'Part Number' },
      { text: 'Status' },
    ],

    open: ['public'],
    files: {
      html: 'mdi-language-html5',
      js: 'mdi-nodejs',
      json: 'mdi-json',
      md: 'mdi-markdown',
      pdf: 'mdi-file-pdf',
      png: 'mdi-file-image',
      txt: 'mdi-file-document-outline',
      xls: 'mdi-file-excel',
    },
    tree: [],
  }),
  apollo: {
    // cardproducts: {
    //   query: CardProducts,
    //   // used because this is easier than updating the cache once a product is created
    //   fetchPolicy: 'network-only',
    //   skip() {
    //     return (
    //       !this.$store.state.auth.user ||
    //       !this.$store.state.auth.user.company_relation
    //     )
    //   },
    //   variables() {
    //     return {
    //       name_search: this.name_search,
    //       id_search: this.id_search,
    //       part_id_search: this.part_id_search,
    //       supplier: this.$store.state.auth.user.company_relation,
    //       limit: this.limit,
    //       start: (this.currentPage - 1) * this.limit,
    //     }
    //   },
    // },

    listproducts: {
      query: ListProducts,
      // used because this is easier than updating the cache once a product is created
      fetchPolicy: 'network-only',
      skip() {
        return (
          !this.$store.state.auth.user ||
          !this.$store.state.auth.user.company_relation
        )
      },
      variables() {
        return {
          supplier: this.$store.state.auth.user.company_relation,
          limit: this.listlimit,
          start: (this.listcurrentPage - 1) * this.listlimit,
          search: this.tableSearch,
        }
      },
    },
    // productsCount: {
    //   query: GetProductsCount,
    //   variables() {
    //     return {
    //       name_search: this.name_search || '',
    //       id_search: this.id_search || '',
    //       part_id_search: this.part_id_search || '',
    //       supplier: this.$store.state.auth.user.company_relation,
    //     }
    //   },
    // },
    productListCount: {
      query: GetProductListCount,
      variables() {
        return {
          search: this.tableSearch || '',
          supplier: this.$store.state.auth.user.company_relation,
        }
      },
    },
  },

  // computed: {
  //   // ...mapActions(['getProductTotalCount']),
  //   productsCountLength() {
  //     var paginationLength = 0
  //     const pagination = this.productsCount / this.limit
  //     if (pagination < 1) {
  //       paginationLength = 1
  //     } else {
  //       paginationLength = Math.ceil(pagination)
  //     }
  //     return parseInt(paginationLength)
  //   },
  // },
  watch: {
    productListCount: {
      immediate: true,
      handler: function(newValue) {
        var paginationLength = 0
        const pagination = newValue / this.listlimit
        if (pagination < 1) {
          paginationLength = 1
        } else {
          paginationLength = Math.ceil(pagination)
        }
        this.productsCountLength = paginationLength > 0 ? paginationLength : 1
      },
    },
    // productsCount: {
    //   immediate: true,
    //   handler: function(newValue) {
    //     var paginationLength = 0
    //     const pagination = newValue / this.limit
    //     if (pagination < 1) {
    //       paginationLength = 1
    //     } else {
    //       paginationLength = Math.ceil(pagination)
    //     }
    //     this.productsCardCountLength =
    //       paginationLength > 0 ? paginationLength : 1
    //   },
    // },
  },
  mounted() {
    if (localStorage.activeTab) {
      this.activeTab = parseInt(localStorage.activeTab)
    }
  },

  methods: {
    async refetch() {
      await this.$apollo.queries.listproducts.refetch()
      await this.$apollo.queries.productListCount.refetch()
      // await this.$apollo.queries.cardproducts.refetch()
    },
    selected(tab) {
      localStorage.activeTab = tab
    },
    // getSearchCount() {
    //   this.getProductTotalCount({
    //     params:
    //       'supplier=' +
    //       this.$store.state.auth.user.company_relation +
    //       '&name_contains=' +
    //       this.name_search +
    //       '&part_id_contains=' +
    //       this.part_id_search +
    //       '&id_contains=' +
    //       this.id_search,
    //   }).then((res) => {
    //     this.totalCount = res.data
    //   })
    // },
    removeAddchip(item) {
      let index = this.addchips.indexOf(item)
      if (index > -1) {
        this.addchips.splice(index, 1)
      }
    },
    async next() {
      // await this.$apollo.queries.cardproducts.refetch()
      await this.$apollo.queries.listproducts.refetch()
    },
    async onSearch() {
      await this.$apollo.queries.listproducts.refetch()
      await this.$apollo.queries.productListCount.refetch()
    },
    // refreshProducts() {
    //   this.$apollo.queries.cardproducts.refetch()
    //   this.$apollo.queries.listproducts.refetch()

    //   this.getProductTotalCount({
    //     params: 'supplier=' + this.$store.state.auth.user.company_relation,
    //   }).then((res) => {
    //     this.totalCount = res.data
    //     this.listtotalCount = res.data
    //   })
    // },
  },
}
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}
</style>

<style lang="scss" scoped>
.card-profile .card-header-image {
  max-height: 215px;
}
.card .card-header.card-header-image {
  position: relative;
  padding: 0;
  z-index: 1;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border-radius: 6px;
}

.card .card-header.card-header-image .colored-shadow {
  transform: scale(0.94);
  top: 12px;
  filter: blur(12px);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  transition: opacity 0.45s;
  opacity: 0;
}

.bg-info {
  background: linear-gradient(60deg, #26c6da, #0097a7);
}

.limit {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
</style>
