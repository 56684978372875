<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="importdialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Import Products</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="container">
      <div class="display-1 mb-5 mt-4">
        Important: The file must be comma-separated only.
      </div>
      <v-spacer></v-spacer>
      <b-form-file
        v-model="csvFile"
        placeholder="Select CSV file"
        class="mb-5"
      ></b-form-file>
      <v-select
        v-model="importDuplicate"
        :items="importDuplicateOptions"
        value="value"
        item-text="text"
        filled
        label="Duplicate Options"
      ></v-select>
      <div
        v-if="errorShow"
        class="display-1 mb-5"
        style="color: red; margin-left: 35px;"
      >
        Validation: These are invalid lines
        <div v-for="(err, index) in importError" :key="index">
          {{ err }}
        </div>
      </div>
      <div
        v-if="validationSuccessShow"
        class="display-1 mb-5"
        style="color: green; margin-left: 35px;"
      >
        Validation success
      </div>
      <div
        v-if="importSuccessShow"
        class="display-1 mb-5"
        style="color: green; margin-left: 35px;"
      >
        Import success
      </div>
      <v-btn elevation="2" rounded @click="downloadTemplate">
        Download Template</v-btn
      >
      <v-divider></v-divider>

      <v-btn color="primary" class="mr-4" @click="$emit('dialog-close')">
        Cancel
      </v-btn>

      <v-btn
        color="success"
        class="mr-4"
        :disabled="!csvFile"
        @click="importProducts"
      >
        Import
      </v-btn>
    </div>

    <v-overlay :opacity="0.46" :value="isLoading">
      <v-progress-circular indeterminate size="36"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Papa from 'papaparse'
import { shopifyItemsToProducts, sampleCSVS } from '@/utilities/import-shopify'
import CreateImportProducts from '@/graphql/mutations/CreateImportProducts.gql'
// import UploadMainImage from '@/graphql/mutations/UploadMainImage.gql'
const importDuplicateOptions = [
  {
    text: 'Ignore Duplicates',
    value: 'ignore',
  },
  {
    text: 'Overwrite Duplicates',
    value: 'overwrite',
  },
]
export default {
  data: function() {
    return {
      csvFile: null,
      reader: null,
      isLoading: false,
      importSuccessShow: false,
      validationSuccessShow: false,
      errorShow: false,
      importDuplicateOptions,
      importDuplicate: importDuplicateOptions[0],
      importError: [],
    }
  },
  methods: {
    downloadTemplate() {
      const anchor = document.createElement('a')
      anchor.href =
        'data:text/csv;charset=utf-8,' + encodeURIComponent(sampleCSVS)
      anchor.target = '_blank'
      anchor.download = 'sampleProductItems.csv'
      anchor.click()
    },
    importProducts() {
      this.errorShow = false
      var reader = new FileReader()
      reader.readAsText(this.csvFile)
      this.reader = reader
      reader.onload = this.onLoad
    },
    async onLoad() {
      this.isLoading = true
      const reader = this.reader
      const fullInput = reader.result
      const { data, errors } = Papa.parse(fullInput, { header: true })
      if (errors.length > 0) {
        this.importError = errors
        this.errorShow = true
        this.isLoading = false
        return
      }
      const productImports = shopifyItemsToProducts(data)

      const newProductImports = productImports.map((product) => {
        const isItem = product.items.filter((item) => {
          return item.sku_id
        })
        // let count = 1
        // const itemsWithVariantSku = isItem.map((item) => {
        //   var temp = Object.assign({}, item)
        //   temp.sku_id = `${item.sku_id}-${count++}`
        //   return temp
        // })
        const noIsItem = product.items.filter((item) => {
          return !item.sku_id
        })
        if (noIsItem.length > 0) {
          const additionalImage = noIsItem.map((item) => {
            return item.main_image
          })
          const newItems = isItem.map((item) => {
            const newItem = { ...item, additional_image: additionalImage }
            return newItem
          })
          const newProduct = {
            ...product.newProduct,
            additional_image: additionalImage,
          }
          const newImportProduct = {
            newProduct: newProduct,
            items: newItems,
          }
          return newImportProduct
        } else {
          const newImportProduct = {
            newProduct: product.newProduct,
            items: isItem,
          }
          return newImportProduct
        }
      })

      // const newProductImportsWithImages = await Promise.all(
      //   newProductImports.map(async (product) => {
      //     var temp = Object.assign({}, product)
      //     const productItems = await Promise.all(
      //       temp.items.map(async (item) => {
      //         var tempItem = Object.assign({}, item)
      //         if (tempItem.additional_image) {
      //           const additionalImages = await Promise.all(
      //             tempItem.additional_image.map(async (image) => {
      //               const urlImage = image
      //               const additionalImage = await this.downloadImage(urlImage)
      //               return additionalImage
      //             })
      //           )
      //           if (additionalImages.length > 0) {
      //             tempItem.additional_image = additionalImages
      //           } else {
      //             tempItem.additional_image = undefined
      //           }
      //         }
      //         const urlItem = item.main_image
      //         const mainImageItem = await this.downloadImage(urlItem)
      //         if (mainImageItem) {
      //           tempItem.main_image = mainImageItem
      //         } else {
      //           tempItem.main_image = undefined
      //         }
      //         return tempItem
      //       })
      //     )
      //     temp.items = productItems
      //     if (product.newProduct.additional_image) {
      //       const additionalImages = await Promise.all(
      //         product.newProduct.additional_image.map(async (image) => {
      //           const urlImage = image
      //           const additionalImage = await this.downloadImage(urlImage)
      //           return additionalImage
      //         })
      //       )
      //       if (additionalImages.length > 0) {
      //         temp.newProduct.additional_image = additionalImages
      //       } else {
      //         temp.newProduct.additional_image = undefined
      //       }
      //     }
      //     const url = product.newProduct.main_image
      //     const mainImage = await this.downloadImage(url)

      //     if (mainImage) {
      //       temp.newProduct.main_image = mainImage
      //     } else {
      //       temp.newProduct.main_image = undefined
      //     }
      //     return temp
      //   })
      // )
      try {
        const isOverwrite =
          this.importDuplicate === importDuplicateOptions[1].value
        await this.$apollo.mutate({
          mutation: CreateImportProducts,
          variables: {
            importProducts: newProductImports,
            isOverwrite: isOverwrite,
          },
        })
        Swal.fire({
          title: '',
          text: 'Import Complete',
          icon: 'success',
          confirmButtonClass: 'btn btn-secondary',
        })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      } finally {
        this.isLoading = false
        this.$emit('refetch')
      }
      // create a new graphql mutation to create products.
    },
    // async downloadImage(url) {
    //   if (url) {
    //     try {
    //       let image = await fetch(url).then((r) => r.blob())
    //       console.log(image)
    //       const mainImage = await this.$apollo.mutate({
    //         mutation: UploadMainImage,
    //         variables: { file: image },
    //       })
    //       return mainImage.data.upload.id
    //     } catch (error) {
    //       return undefined
    //     }
    //   } else {
    //     return undefined
    //   }
    // },
  },
}
</script>

<style></style>
