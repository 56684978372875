import { groupBy } from 'lodash'
const newItemsSku = []
const newItemsWithVariantSku = []
const newItemsImage = []

function shopifyItemToProductItem(item) {
  if (item[`Option1 Value`] && item[`Variant SKU`]) {
    const information = {
      part_id: item[`Handle`],
      long_description: item[`Body (HTML)`],
      main_image: item[`Image Src`],
      // item[`Cost per item`],
      // item[`Gift Card`],
      // item[`Google Shopping / AdWords Grouping`],
      // item[`Google Shopping / AdWords Labels`],
      // item[`Google Shopping / Age Group`],
      // item[`Google Shopping / Condition`],
      // item[`Google Shopping / Custom Label 0`],
      // item[`Google Shopping / Custom Label 1`],
      // item[`Google Shopping / Custom Label 2`],
      // item[`Google Shopping / Custom Label 3`],
      // item[`Google Shopping / Custom Label 4`],
      // item[`Google Shopping / Custom Product`],
      // item[`Google Shopping / Gender`],
      // item[`Google Shopping / Google Product Category`],
      // item[`Google Shopping / MPN`],
      // item[`Image Alt Text`],
      // item[`Image Position`],
      // main_image: item[`Image Src`],
      //  item[`Option1 Name`],
      Name: item[`Option1 Value`],
      // item[`Option2 Name`],
      // item[`Option2 Value`],
      // item[`Option3 Name`],
      // item[`Option3 Value`],
      // item[`Published`],
      // item[`SEO Description`],
      // item[`SEO Title`],
      // item[`Status`],
      // TODO see if we need to include tags
      // tags: item[`Tags`],
      product: {
        name: item[`Title`],
        main_image: item[`Image Src`],
        long_description: item[`Body (HTML)`],
        // tags: item[`Tags`],
      },
      // item[`Type`],
      // barcode: item[`Variant Barcode`],
      // item[`Variant Compare At Price`],
      // item[`Variant Fulfillment Service`],
      // item[`Variant Grams`],
      // item[`Variant Image`],
      // item[`Variant Inventory Policy`],
      // item[`Variant Inventory Qty`],
      // item[`Variant Inventory Tracker`],
      // item[`Variant Price`],
      // item[`Variant Requires Shipping`],
      sku_id: item[`Variant SKU`],
      // item[`Variant Tax Code`],
      // item[`Variant Taxable`],
      // item[`Variant Weight Unit`],
      // item[`Vendor`],
    }

    const filteredProductInformation = objectFilter(
      information.product,
      (info) => info
    )
    const filteredInformation = objectFilter(information, (info) => info)
    const sendInformation = {
      ...filteredInformation,
      product: filteredProductInformation,
    }
    newItemsSku.push(sendInformation)
    // return sendInformation
  } else if (item[`Option1 Value`] && item[`Handle`]) {
    // const m = item[`Handle`].concat(`-${count++}`)
    // console.log(m)
    const information = {
      part_id: item[`Handle`],
      long_description: item[`Body (HTML)`],
      main_image: item[`Image Src`],
      // item[`Cost per item`],
      // item[`Gift Card`],
      // item[`Google Shopping / AdWords Grouping`],
      // item[`Google Shopping / AdWords Labels`],
      // item[`Google Shopping / Age Group`],
      // item[`Google Shopping / Condition`],
      // item[`Google Shopping / Custom Label 0`],
      // item[`Google Shopping / Custom Label 1`],
      // item[`Google Shopping / Custom Label 2`],
      // item[`Google Shopping / Custom Label 3`],
      // item[`Google Shopping / Custom Label 4`],
      // item[`Google Shopping / Custom Product`],
      // item[`Google Shopping / Gender`],
      // item[`Google Shopping / Google Product Category`],
      // item[`Google Shopping / MPN`],
      // item[`Image Alt Text`],
      // item[`Image Position`],
      // main_image: item[`Image Src`],
      //  item[`Option1 Name`],
      Name: item[`Option1 Value`],
      // item[`Option2 Name`],
      // item[`Option2 Value`],
      // item[`Option3 Name`],
      // item[`Option3 Value`],
      // item[`Published`],
      // item[`SEO Description`],
      // item[`SEO Title`],
      // item[`Status`],
      // TODO see if we need to include tags
      // tags: item[`Tags`],
      product: {
        name: item[`Title`],
        main_image: item[`Image Src`],
        long_description: item[`Body (HTML)`],
        // tags: item[`Tags`],
      },
      // item[`Type`],
      // item[`Variant Barcode`],
      // item[`Variant Compare At Price`],
      // item[`Variant Fulfillment Service`],
      // item[`Variant Grams`],
      // item[`Variant Image`],
      // item[`Variant Inventory Policy`],
      // item[`Variant Inventory Qty`],
      // item[`Variant Inventory Tracker`],
      // item[`Variant Price`],
      // item[`Variant Requires Shipping`],
      sku_id: item[`Handle`],
      // item[`Variant Tax Code`],
      // item[`Variant Taxable`],
      // item[`Variant Weight Unit`],
      // item[`Vendor`],
    }
    const filteredProductInformation = objectFilter(
      information.product,
      (info) => info
    )
    const filteredInformation = objectFilter(information, (info) => info)
    const sendInformation = {
      ...filteredInformation,
      product: filteredProductInformation,
    }
    newItemsWithVariantSku.push(sendInformation)
    // return sendInformation
  } else {
    const information = {
      part_id: item[`Handle`],
      long_description: item[`Body (HTML)`],
      main_image: item[`Image Src`],
      // item[`Cost per item`],
      // item[`Gift Card`],
      // item[`Google Shopping / AdWords Grouping`],
      // item[`Google Shopping / AdWords Labels`],
      // item[`Google Shopping / Age Group`],
      // item[`Google Shopping / Condition`],
      // item[`Google Shopping / Custom Label 0`],
      // item[`Google Shopping / Custom Label 1`],
      // item[`Google Shopping / Custom Label 2`],
      // item[`Google Shopping / Custom Label 3`],
      // item[`Google Shopping / Custom Label 4`],
      // item[`Google Shopping / Custom Product`],
      // item[`Google Shopping / Gender`],
      // item[`Google Shopping / Google Product Category`],
      // item[`Google Shopping / MPN`],
      // item[`Image Alt Text`],
      // item[`Image Position`],
      // main_image: item[`Image Src`],
      //  item[`Option1 Name`],
      Name: item[`Option1 Value`],
      // item[`Option2 Name`],
      // item[`Option2 Value`],
      // item[`Option3 Name`],
      // item[`Option3 Value`],
      // item[`Published`],
      // item[`SEO Description`],
      // item[`SEO Title`],
      // item[`Status`],
      // TODO see if we need to include tags
      // tags: item[`Tags`],
      product: {
        name: item[`Title`],
        main_image: item[`Image Src`],
        long_description: item[`Body (HTML)`],
        // tags: item[`Tags`],
      },
      // item[`Type`],
      // item[`Variant Barcode`],
      // item[`Variant Compare At Price`],
      // item[`Variant Fulfillment Service`],
      // item[`Variant Grams`],
      // item[`Variant Image`],
      // item[`Variant Inventory Policy`],
      // item[`Variant Inventory Qty`],
      // item[`Variant Inventory Tracker`],
      // item[`Variant Price`],
      // item[`Variant Requires Shipping`],
      sku_id: item[`Variant SKU`],
      // item[`Variant Tax Code`],
      // item[`Variant Taxable`],
      // item[`Variant Weight Unit`],
      // item[`Vendor`],
    }
    const filteredProductInformation = objectFilter(
      information.product,
      (info) => info
    )
    const filteredInformation = objectFilter(information, (info) => info)
    const sendInformation = {
      ...filteredInformation,
      product: filteredProductInformation,
    }
    newItemsImage.push(sendInformation)
    // return sendInformation
  }
}
function objectFilter(obj, predicate) {
  return Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {})
}

function shopifyItemsToProducts(items) {
  // let count = 1

  items.map(shopifyItemToProductItem)
  let count = 2
  const groupWithVariantsSku = groupBy(newItemsWithVariantSku, 'part_id')

  const itemsWithVariantSku = Object.keys(groupWithVariantsSku).flatMap(
    function(key) {
      const newItemsVariantSku = groupWithVariantsSku[key].map(
        (item, index) => {
          if (index != 0) {
            var temp = Object.assign({}, item)
            temp.sku_id = `${item.sku_id}-${count++}`
            return temp
          } else {
            return item
          }
        }
      )
      return newItemsVariantSku
    }
  )

  const newItems = [...newItemsSku, ...itemsWithVariantSku, ...newItemsImage]
  const itemsByProduct = groupBy(newItems, 'part_id')
  const fullProductInputs = Object.keys(itemsByProduct).map(function(key) {
    return {
      newProduct: {
        part_id: key,
        ...itemsByProduct[key][0].product,
      },
      items: itemsByProduct[key].map((i) => ({
        ...i,
        // remove fields that aren't needed
        product: undefined,
        part_id: undefined,
      })),
    }
  })
  return fullProductInputs
}
const sampleCSVS = `Handle,Title,Body (HTML),Vendor,Type,Tags,Published,Option1 Name,Option1 Value,Option2 Name,Option2 Value,Option3 Name,Option3 Value,Variant SKU,Variant Grams,Variant Inventory Tracker,Variant Inventory Qty,Variant Inventory Policy,Variant Fulfillment Service,Variant Price,Variant Compare At Price,Variant Requires Shipping,Variant Taxable,Variant Barcode,Image Src,Image Position,Image Alt Text,Gift Card,SEO Title,SEO Description,Google Shopping / Google Product Category,Google Shopping / Gender,Google Shopping / Age Group,Google Shopping / MPN,Google Shopping / AdWords Grouping,Google Shopping / AdWords Labels,Google Shopping / Condition,Google Shopping / Custom Product,Google Shopping / Custom Label 0,Google Shopping / Custom Label 1,Google Shopping / Custom Label 2,Google Shopping / Custom Label 3,Google Shopping / Custom Label 4,Variant Image,Variant Weight Unit,Variant Tax Code,Cost per item,Status
example-t-shirt,Example T-Shirt,,Acme,Shirts,mens t-shirt example,TRUE,Title,"Lithograph - Height: 9"" x Width: 12""",,,,,,3629,,,deny,manual,25,,TRUE,TRUE,,https://burst.shopifycdn.com/photos/green-t-shirt.jpg,1,,FALSE,Our awesome T-shirt in 70 characters or less.,A great description of your products in 320 characters or less,Apparel & Accessories > Clothing,Unisex,Adult,7X8ABC910,T-shirts,"cotton, pre-shrunk",used,FALSE,,,,,,,g,,,active
example-t-shirt,,,,,,,,Small,,,,,example-shirt-s,200,,,deny,manual,19.99,24.99,TRUE,TRUE,,,,,,,,,,,,,,,,,,,,,,g,,,
example-t-shirt,,,,,,,,Medium,,,,,example-shirt-m,200,shopify,,deny,manual,19.99,24.99,TRUE,TRUE,,,,,,,,,,,,,,,,,,,,,,g,,,
example-pants,Example Pants,,Acme,Pants,mens pants example,FALSE,Title,"Jeans, W32H34",,,,,,1250,,,deny,manual,49.99,57.99,TRUE,TRUE,,https://burst.shopifycdn.com/photos/distressed-kids-jeans.jpg,1,,FALSE,Our awesome Pants in 70 characters or less.,A great description of your products in 320 characters or less,Apparel & Accessories > Clothing,Unisex,Adult,7Y2ABD712,Pants,"cotton, pre-shrunk",used,FALSE,,,,,,,g,,,draft
example-hat,Example Hat,,Acme,Hat,mens hat example,FALSE,Title,Grey,,,,,,275,,,deny,manual,17.99,22.99,TRUE,TRUE,,https://burst.shopifycdn.com/photos/kids-beanie.jpg,1,,FALSE,Our awesome Hat in 70 characters or less.,A great description of your products in 320 characters or less,Apparel & Accessories > Clothing,Unisex,Adult,5P1NBQ314,Hat,"cotton, pre-shrunk",used,FALSE,,,,,,,g,,,archived`

export { shopifyItemsToProducts, sampleCSVS }
